
import { Component, Vue } from "vue-property-decorator";
import { WechatService,OrderService, ReportService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { Pay, tools } from "src/utils";
import globalConfig from "src/config/index";
import udialog from "src/components/dialog";
import router from "src/router";

Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteLeave",
    "beforeRouteUpdate" // for vue-router 2.2+
]);


@Component({
    components:{
        udialog
    }
})
export default class AppointCheck extends Vue
{
    

    /**
     * 是否显示更多弹框
     * @private
     * @returns 
     */
     private showPopover: boolean = false;

    /**
     * 基础数据
     * @private
     * @returns any
     */
    private state: any = {
        addItemNum: 0,
        isLimited: true,
        isNotifyFamily: false
    };

    /**
     * 是否显示延时
     * @private
     * @returns boolean
     */
    private loadingMode: boolean = true;

    /**
     * 是否显示弹框
     * @private
     * @returns boolean
     */
    private isShowDialog: boolean = false;

    /**
     * 弹框名称
     * @private
     * @returns boolean
     */
    private dialogName: string = "";

     /**
     * 弹框内容
     * @private
     * @returns boolean
     */
    private dialogContent: string = "";

    /**
     * 时间处理
     * @private
     * @returns 
     */
    private dayjs: any = dayjs;

    /**
     * 当前加项包的项目
     * @private
     * @returns Array<any>
     */ 
     private addPackItems: Array<any> = [];

    /**
     * 温馨提示
     * @private
     * @returns 
     */
    private showOverlay: boolean = false;

    /**
     * 是否显示日期
     * @private
     * @returns 
     */
    private showDate: boolean = false;

    /**
     * 订单详情
     * @private
     * @returns 
     */
    private orderDetail: any = {};
    
    /**
     * 根据预约日期判断能否取消，(今天12点前或前一天不能取消)
     * @private
     * @returns boolean
     */
    private get isCancel(): boolean
    {
        let orderDate = this.orderDetail.physicalTime;
        // 判断体检日期是不是明天
        let isTomorrow = dayjs().add(1,"day").format("YYYY-MM-DD") === dayjs(orderDate).format("YYYY-MM-DD");
        // 判断是否是今天
        let isToDay = dayjs(orderDate).format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD");
        if(isTomorrow)
        {
            return false;
        }
        // else if(isToDay && (+dayjs(orderDate).format("hh") < 12))
        else if(isToDay)
        {
            return false;
        }

        return  true;
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 是否到支付成功页
     * @private
     * @returns number
     */
     private get isPaySuccessPage(): boolean
    {
        if(this.$route.query && this.$route.query.isPaySuccessPage)
        {
           return this.$route.query.isPaySuccessPage as any;
        }

        return null;
    }

    /**
     * 是否已读
     * @private
     * @returns number
     */
     private get isRead()
    {
        if(this.$route.query && this.$route.query.isRead)
        {
           return this.$route.query.isRead;
        }

        return null;
    }


    /**
     * 页面是否回退-h5环境
     * @private
     * @returns boolean
     */
    private get goback(): boolean
    {
        if(this.$route.query && this.$route.query.goback)
        {
           return true;
        }

        return false;
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private async created(): Promise<void>
    {
        this.getOrderDetail();
    }

    /**
     * 获取订单详情
     * @private
     * @param {params}
     * @returns {Promise<void>}
     */
    private async getOrderDetail(): Promise<void>
    {
        try
        {
            let {content: result} =  await OrderService.instance.appointCheck({needInfo: true});

            if(result?.data)
            {
                this.orderDetail = result.data.order;
                if(this.isPaySuccessPage && (this.orderDetail.orderStatus === 2 || this.orderDetail.orderStatus === 3)) {
                    this.$router.replace({name: "orderMiddle", query:{id: this.orderDetail.id,hasHealthProduct: this.orderDetail.hasHealthProduct+"",  orderStatus: this.orderDetail.orderStatus + "" }});
                }
                this.state.addItemNum = result.data.addItemNum;
                this.state.isLimited = result.data.isLimited;
                this.state.isNotifyFamily = result.data.isNotifyFamily;
                this.loadingMode = false;
                if (!this.state.isLimited)
                {
                    this.onHome();
                }
            }
        }
        catch(err)
        {
            Toast(err);
        }
        
    }

    /**
     * 查看报告
     * @private
     * @returns string
     */
    private async onShowReport()
    {
        // 判断是否已验证
        let {content:result} = await ReportService.instance.checkIdentity()
        if(!result.data.checked) {
            router.push({ name: "identityVerify",query:{isAuth:this.orderDetail.isAuth,id: this.orderDetail.reportId + ""}});
        } else {
        // 判断是否已授权 
            if(this.orderDetail.isAuth) {
                this.$router.push({name: "reportAppointmentDetail", query: {id: this.orderDetail.reportId + ""}});
            } else {
                this.$router.push({name: "reportQuery", query: {id: this.orderDetail.reportId + ""}});
            }
        }
        // 本地存储数据
        let data = {
                id: this.orderDetail.employerMemberId, 
                name: this.orderDetail.memberName,
                phone:this.orderDetail.memberPhone,
        }
        this.$store.dispatch("setReportParams",data)

    }

    /**
     * @description 跳转到评价页面
     * @private
     * @returns 
     */
    protected evaluate({ id, orderEvaluationId }): void {
        this.$router.push({ name: "orderEvaluate", params: { id: id }, query: { orderEvaluationId: orderEvaluationId } })
    }

    /**
     * 组件进来之前
     * @private
     * @returns void
     */
    private beforeRouteEnter(to, from, next): void
    {
        let appid = localStorage.getItem("appid");
        if (!tools.isAndroid() && `/${appid}${to.path}` !== location.pathname)
        {
        location.assign(`/${appid}${to.fullPath}`);
        }
        else
        {
            next();
        }
    }

    /**
     * 继续支付
     * @private
     * @param {params}
     * @returns {Promise<void>}
     */
    private async proceedPay(): Promise<void>
    {
        this.$router.push({name: "pay", query:{orderNo: this.orderDetail.orderNo}});
        
    }

    /**
     * 显示日期弹框
     * @private
     * @returns void
     */
    private showDateModal(): void
    {
        this.showDate = true;
    }

    /**
     * 详情
     * @private
     * @returns void
     */
    private onSpecialDetail(id: string): void
    {
        this.$router.push({name:"rightsDetail", params:{id: id}})
    }

    /**
     * 确定改约
     * @private
     * @returns void
     */
    private onDateConfirm(date: string,physicalTimePeriod: string ): any
    {
        if(!date) return;

        let data = dayjs(date).format("YYYY-MM-DD");
            Dialog.confirm({
            title: "改约日期",
            message: `是否确定改约${data} ${physicalTimePeriod}体检`
        })
        .then(async() => {
            try
            {
                let result:any =  await OrderService.instance.modifiedOrderDate(this.orderDetail.id, data, physicalTimePeriod);

                if(result.code == 1000)
                {
                    this.showOverlay = true;
                }
                else
                {
                    Toast({
                        duration: 1000,
                        message: `${result.content && result.content.data && result.content.data.isHandling ? '改约处理中' : '改约成功'}`
                    });
                    this.getOrderDetail();
                }
            }
            catch(err)
            {               
                Toast(err);
            }
            finally
            {
                this.showDate = false;
            }
            
        });
    }
    
    /**
     * 取消订单
     * @private
     * @param {params}
     * @returns {Promise<void>}
     */
    private onCancelOrder(): void
    {
        Dialog.confirm({
            title: "取消订单",
            message: "是否确定取消订单? 取消后需要重新预约"
        })
        .then(async() => {
            try
            {
                let  result: any =  await OrderService.instance.cancelOrder(this.orderDetail.id);
                if(result.code == 1000)
                {
                    this.showOverlay = true;
                }
                else
                {
                    Toast({
                        duration: 1000,
                        message: `${result.content && result.content.data && result.content.data.isHandling ? '取消处理中' : '取消成功'}`
                    });
                    this.getOrderDetail();
                }
            }
            catch(err)
            {
                Toast(err);
            }
        });
    }

    /**
     * 显示提示
     * @private
     * @returns void
     */
    private showTip(remark: string,name:string): void
    {
        // Dialog.alert({
        //     messageAlign: "left",
        //     confirmButtonColor: "#00d5c1",
        //     message: remark,
        //     confirmButtonText: "确定"
        // });

        this.dialogName = name
        this.dialogContent = remark
        this.isShowDialog = true
    }

    /**
     * 跳转页面
     * @private
     * @returns void
     */
    private onGopage(name: string): void
    {
        this.$router.push({name});
    }

    /**
     * 获取企业参数配置
     * @private
     * @returns any
     */
    private get generalSettings(): any
    {
        let generalSettingsValue = localStorage.getItem("generalSettings");
        if(generalSettingsValue)
        {
            return JSON.parse(generalSettingsValue);
        }
        return [];
    }

    /**
     * 跳转地图
     * @private
     * @returns void
     */
    private async openMap(hospitalOut: any): Promise<any>
    {
        if(hospitalOut)
        {
            let lat = hospitalOut.latitude;
            let lng =hospitalOut.longitude;;
            let address = hospitalOut.address;
            let orgName = hospitalOut.hospitalName;

            if(await tools.isWechat())
            {
                let currentUrl = location.href;
                let {content: result} = await WechatService.instance.getWechatJSSDK(currentUrl);
                if(result.data)
                {
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: result.data.appId, // 必填，公众号的唯一标识
                        timestamp: result.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
                        signature: result.data.signature, // 必填，签名
                        jsApiList: ["openLocation"] // 必填，需要使用的JS接口列表
                    });

                    wx.ready(function()
                    {
                        wx.openLocation({
                            latitude: parseFloat(lat), // 纬度，范围为-90~90，负数表示南纬
                            longitude: parseFloat(lng), // 经度，范围为-180~180，负数表示西经
                            scale: 14, // 缩放比例
                            name: orgName,
                            address: address, // 地图缩放级别,整形值,范围从1~28。默认为最大
                            infoUrl: ""
                        });
                    });
                }
            }
            else
            {
                // 腾讯地图
                // let href = `https://apis.map.qq.com/uri/v1/marker?marker=coord:${lat},${lng};addr:${this.orgDetailInfo.org_name}`;
                // 高德
                let href = `https://uri.amap.com/marker?position=${lng},${lat}&name=${orgName}&coordinate=gaode&callnative=1`;
                window.open(href);
            }

        }

    }

    /**
     * 是否显示配置
     * @protected
     * @returns boolean
     */
    protected showStting(code: string): boolean
    {
        // A1001 -- 体检套餐列表页、详情页、提交订单页及订单详情页均不显示体检套餐价格及企业报销金额。
        // A1002 -- 体检套餐列表页、详情页均不显示体检套餐划线价格。
        let data = this.generalSettings.filter(res => res.code == code);
        if (data.length > 0)
        {
            return data[0].isOpen;
        }
        else
        {
            return true;
        }
    }

    /**
     * 复制
     * @param str 
     */
    private async copyOrderNo(str): Promise<void>
    {
        let save = function (e) {
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        };
        let input = document.createElement("input");
        input.value = str;
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, input.value.length), document.execCommand("Copy");
        document.body.removeChild(input);
        Toast({
            message: "复制成功",
            duration: 1000
        });
    }

    /**
     * 跳转订单详情
     * @protected
     */
    protected onPageDetail(): void
    {
        this.$router.push({name: "orderDetail", params:{id: this.orderDetail.id}});
    }

    /**
     * 家属预约
     * @protected
     */
    protected onFamilyMake(): void
    {
        this.$router.push({ name: "physical-package", query: { type: "2" } });
    }

    /**
     * 跳转首页
     * @protected
     */
    protected onHome(): void
    {
        this.$router.go(-1);
    }

    /**
     * 查看所有可选套餐
     * @protected
     */
    protected onPackage(): void
    {
        this.$router.push({ name: "physical-package", query: { type: "1" } });
    }
    

}
