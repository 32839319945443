
import { Component, Vue } from "vue-property-decorator";
import { Toast } from "vant";
import { Service } from "src/decorator";
import { OrderService } from "src/services";
import dayjs from "dayjs";
import goods from "./sub/goods.vue";
import medical from "./sub/medical.vue";
import physical from "./sub/physical.vue";
import healthInsurance from "./sub/healthInsurance.vue";
import globalConfig from "src/config/index";
import rights from "./sub/rights.vue";

@Component({
    components:
    {
        goods,
        medical,
        physical,
        rights,
        healthInsurance
    }
})
export default class OrderList extends Vue
{
    /**
     * 是否是ai跳转过来
     * @private
     * @returns boolean
     */
     private get isAi(): boolean
    {
        return (this.$route.query && this.$route.query.isAi == 'true') ? true : false;
    }

    /**
     * 搜索关键字
     * @private
     * @returns string
     */
    private filters: any =
    {
        status: ""
    };

    /**
     * 是否显示权益中心
     * @private
     * @returns boolean
     */
     public hasRights: boolean = true;

    /**
     * 加载
     */
    private loading:boolean = false
    /**
     * tab选中
     * @private
     * @returns number | string
     */
    private active: any = "";

    /**
     * 时间处理工具
     * @private
     * @returns dayjs
     */
    private dayjs: any = dayjs;

    /**
     * 订单列表
     * @private
     * @returns Array<any>
     */
    private orderList: Array<any> = [];

    /**
     * 获取当前登入信息
     * @private
     * @returns void
     */
    private get userInfo(): any
    {
        return this.$store.getters.userInfo || {};
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }


    /**
     * 获取订单数量
     * @private
     * @returns void
     */
     private async getOrderQuantity(): Promise<void>
    {
        try
        {
            let { content: result } = await OrderService.instance.getOrderQuantity();
            if(result.data)   // 订单数量
            {
                this.hasRights = result.data.hasRights || false;
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 订单状态
     * @private
     * @returns number
     */
    private get reserveType(): any
    {
        return this.$route.query.type;
    }

    /**
     * 是否展示待激活
     * @private
     * @returns boolean
     */
     private get isShow(): boolean
    {
        return this.$route.query.isShow == 'true' ? true : false;
    }

    /**
     * 获取订单列表
     * @private
     * @returns void
     */
    private async getOrderList(): Promise<void>
    {
        try
        {
            let filters = Object.assign(this.filters);
            let { content: result } = await OrderService.instance.getOrderList(filters);
            if(result.data)
            {
                this.orderList = result.data || [];
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        this.filters.status  = this.reserveType;
        this.active  = this.reserveType;
        this.getOrderList();
        this.getOrderQuantity()
        
        window.addEventListener('pageshow', () => {
            this.filters.status  = this.reserveType;
            this.active  = this.reserveType;
            this.getOrderList();
            //外链回退到vue应用要执行的代码
            
        })
        
    }

    /**
     * tab切换
     * @private
     * @returns void
     */
    private onTabChange(type: any): void
    {
        this.filters.status = type;
        if (type)
        {
            this.$router.replace({name: "order-list", query: {type, isShow: (this.$route.query && this.$route.query.isShow) || null, isAi: this.isAi + ""}});
        }
        else
        {
            this.$router.replace({name: "order-list", query: { isShow: (this.$route.query && this.$route.query.isShow) || null, isAi: this.isAi + "" }}, () => {});
        }
        this.getOrderList();
    }

    /**
     * 刷新数据
     * @private
     * @returns void
     */
    private onRefresh(): void
    {
        setTimeout(() => {
            Toast('刷新成功');
            this.loading = false;
        }, 1000);

        this.getOrderList();
    }
}
