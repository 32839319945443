
import { Component, Vue, Prop } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { Service } from "src/decorator";
import { OrderService, ReportService } from "src/services";
import dayjs from "dayjs";
import { Pay, tools } from "src/utils";
import router from "src/router";

@Component
export default class Goods extends Vue
{
    /**
     * 是否是ai跳转过来
     * @private
     * @returns boolean
     */
     private get isAi(): boolean
    {
        return (this.$route.query && this.$route.query.isAi == 'true') ? true : false;
    }

    /**
     * 数据
     * @private
     * @returns string
     */
    @Prop({type: Object, default: ()=>({})})
    private item: any;

    /**
     * 是否显示日期
     * @private
     * @returns 
     */
     private showDate: boolean = false;

    /**
     * 显示日期弹框
     * @private
     * @returns void
     */
    private showDateModal(): void
    {
        this.showDate = true;
    }

    /**
     * 继续支付
     * @private
     * @param {params}
     * @returns {Promise<void>}
     */
    private async proceedPay(id): Promise<void>
    {
        this.$router.push({name: "pay", query:{orderNo: this.item.orderNo}});
        // try
        // {
        //     let {content: result} =  await OrderService.instance.proceedPay(id);

        //     if(result)
        //     {
                
        //         // Pay.onBridgeReady(result.data, this.getOrderList);
        //         this.$router.push({name: "pay", query:{orderNo: result.data.orderNo}});
                
        //     }
        // }
        // catch(err)
        // {
        //     Toast(err);
        // }
        
    }

    /**
     * @description 跳转到评价页面
     * @private
     * @returns 
     */
    protected evaluate({ id, orderEvaluationId }): any {
        this.$router.push({ name: "orderEvaluate", params: { id: id }, query: { orderEvaluationId: orderEvaluationId } })
    }

    /**
     * 跳转详情
     * @private
     * @returns string
     */
    private onDetail(item): any
    {
        this.$router.push({name:"orderDetail", params:{id:item.id},query:{reportId:item.reportId,isRead:item.isRead, isAi: this.isAi + ""}})
    }

    /**
     * 查看报告
     * @private
     * @returns string
     */
    private async onShowReport(item)
    {
            let {content:result} = await ReportService.instance.checkIdentity()
            if(!result.data.checked) {
                this.$router.push({ name: "identityVerify",query:{isAuth:item.isAuth,id: item.reportId + ""} });
            } else {
            // 判断是否已授权 
                if(item.isAuth) {
                    this.$router.push({name: "reportAppointmentDetail", query: {id: item.reportId + ""}});
                } else {
                    this.$router.push({ name:"reportQuery",query:{id: item.reportId + ""}});
                }
            }

            // 本地存储数据
        let data = {
                id: item.employerMemberId, 
                name: item.memberName,
                phone:item.memberPhone,
        }
        this.$store.dispatch("setReportParams",data)
    }

    /**
     * 温馨提示
     * @private
     * @returns 
     */
    private showOverlay: boolean = false;

    /**
     * 确定改约
     * @private
     * @returns void
     */
     private onDateConfirm(date: string,physicalTimePeriod: string ): any
    {
        if(!date) return;

        let data = dayjs(date).format("YYYY-MM-DD");
            Dialog.confirm({
            title: "改约日期",
            message: `是否确定改约${data} ${physicalTimePeriod}体检`
        })
        .then(async() => {
            try
            {
                let result:any = await OrderService.instance.modifiedOrderDate(this.item.id, data, physicalTimePeriod);
                if(result.code == 1000)
                {
                    this.showOverlay = true;
                }
                else
                {
                    this.$emit("refresh");
                    Toast({
                        duration: 1000,
                        message: `${result.content && result.content.data && result.content.data.isHandling ? '改约处理中' : '改约成功'}`
                    });
                }
            }
            catch(err)
            {
                Toast(err);
            }
            finally
            {
                this.showDate = false;
            }
            
        });
    }

    /**
     * 继续支付
     * @private
     * @param {params}
     * @returns {Promise<void>}
     */
    private async getOrderList(id): Promise<void>
    {
       this.$emit("refresh");
        
    }

    /**
     * 获取企业参数配置
     * @private
     * @returns any
     */
    private get generalSettings(): any
    {
        let generalSettingsValue = localStorage.getItem("generalSettings");
        if(generalSettingsValue)
        {
            return JSON.parse(generalSettingsValue);
        }
        return [];
    }

    /**
     * 是否显示配置
     * @protected
     * @returns boolean
     */
    protected showStting(code: string): boolean
    {
        // A1001 -- 体检套餐列表页、详情页、提交订单页及订单详情页均不显示体检套餐价格及企业报销金额。
        let data = this.generalSettings.filter(res => res.code == code);
        if (data.length > 0)
        {
            return data[0].isOpen;
        }
        else
        {
            return true;
        }
    }
}
