
import { Component, Vue } from "vue-property-decorator";
import { Toast, Dialog } from "vant";
import { OrderService } from "src/services";
import dayjs from "dayjs";
import router from "src/router";
import globalConfig from "src/config/index";

Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteLeave",
    "beforeRouteUpdate" // for vue-router 2.2+
]);

@Component({
    components: {

    }
})
export default class OrderEvaluate extends Vue {
    /**
     * @description checkRadio 
     */
    private checkRadio: boolean = false
    /**
     * @function changeAnonymous
     */
    private changeAnonymous(boo:boolean) {
        this.checkRadio = boo;
    }

    /**
     * @function clickFn
     * @description 点击选择或者反选匿名
     */
    protected clickFn() {
        if (!this.checkRadio) {
            this.evaluateList.isAnonymous = false;
        } else {
            this.evaluateList.isAnonymous = true;
        }
        this.checkRadio = false;
    }

    protected orderId: any = 0
    protected orderEvaluationId: any = 0
    protected submited: boolean = false;
    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    protected created(): void {
        if (this.$route.params && this.$route.params.id) {
            this.orderId = this.$route.params.id;
        }
        if (this.$route.query && this.$route.query.orderEvaluationId) {
            this.orderEvaluationId = this.$route.query.orderEvaluationId;
        }
        
        if (this.orderEvaluationId === 0) {
            this.getEvaluateList(); // 还没评价的时候调用
            this.removeCacheData();
            this.submited = false;
        } else {
            this.getEvaluateInfo();
            this.contentDisplay = true;
            this.submited = true;
        }

        window.addEventListener('pageshow', () => {
            if (this.orderEvaluationId === 0) {
                this.getEvaluateList(); // 还没评价的时候调用
                this.removeCacheData();
                this.submited = false;
            } else {
                this.getEvaluateInfo();
                this.contentDisplay = true;
                this.submited = true;
            }
        })
    }

    private mounted() {
        if (window.history && window.history.pushState) {
            if (this.orderEvaluationId === 0) {
                history.pushState(null, null, '#1');
                window.addEventListener('popstate', this.backChange, false);
            }
        }
    }

    private showBack:boolean = false;

    private backChange(){
        this.showBack = true;  //逻辑
    }

    private destroyed(){
        this.cacheEvaluate();
        window.removeEventListener('popstate', this.backChange, false);
        history.replaceState(null, null, '');
    }

    /**
     * 在考虑下
     * @private
     * @returns void
     */
    private onAgree () {
        this.showBack = false
        return
    }

    /**
     * 退出
     * @private
     * @returns void
     */
    private laterView() {
        this.showBack= false
        window.removeEventListener('popstate', this.backChange, false);
        this.$router.go(-1);
    }

    protected get formatDate(): string {
        return dayjs(this.evaluateList.createdTime).format("YYYY-MM-DD") || ""
    }

    /**
     * evaluateTime
     */
    protected evaluateTime: string = ""

    /**
     * @description showPopover
     */
    public showPopover:boolean = false;
    
    /**
     * @description showBtn
     */
    protected get showBtn(): boolean {
        if (this.$route.query && this.$route.query.orderEvaluationId) {
            return false;
        } else {
            return true;
        }
    }

    /**
     * 加载
     */
    public loading: boolean = false
    /**
     * 默认的踩的图标
     */
    protected disName: string = "good-job-o"

    /**
     * @description evaluateList
     */
    private evaluateList: any = {
        likeThisProduct: null,
        overallRating: 0,
        appointmentProcessRating: 0,
        orgEnvironmentRating: 0,
        healthServicesRating: 0,
        comment: "",
        isAnonymous: false,
    }

    /**
     * 获取订单信息
     * @private
     * @returns void
     */
    public async getEvaluateList(): Promise<void> {
        try {
            let param = {
                orderId: this.orderId
            }
            let { content: result } = await OrderService.instance.evaluateBeforeAdd(param);
            if (result.data) {
                this.evaluateList = { ...this.evaluateList, ...result.data };
            }
        }
        catch (err) {
            Toast(err);
        }
    }

    // 是否展示产品
    private showProduct: boolean = true
    /**
     * @function getEvaluateInfo
     * @description 评价过得查询评价详情
     */
    private async getEvaluateInfo(): Promise<void> {
        try {
            let { content: result } = await OrderService.instance.getEvaluateData(this.orderEvaluationId);
            if (result.data) {
                this.evaluateList = { ...this.evaluateList, ...result.data };
                if (result.data.likeThisProduct === true) {
                    this.iconColar1 = '#FF7F19'
                    this.iconColar2 = '#333333'
                    this.disName = "good-job-o"
                    this.showProduct = true
                } else if (result.data.likeThisProduct === false) {
                    this.disName = "good-job"
                    this.iconColar1 = '#333333'
                    this.iconColar2 = '#C7C7C7'
                    this.showProduct = true
                } else {
                    this.disName = "good-job-o"
                    this.iconColar1 = '#333333'
                    this.iconColar2 = '#333333'
                    this.showProduct = false
                }
                if (result.data) {
                    this.evaluateTime = dayjs(result.data.createdTime).format('YYYY-MM-DD')
                }
            }
        }
        catch (err) {
            Toast(err);
        }
    }

    // 默认过期时间
    protected expiraTime: any = 0
    /**
     * @function removeCacheData
     * @description 超过24小时，删除存起来的评价数据，否则加载这些数据
     */
    protected removeCacheData() {
        const nowTime = new Date().getTime()
        const key = this.orderId.toString()
        const cacheData = JSON.parse(localStorage.getItem(key))
        if(!!cacheData){
            const { overallRating, appointmentProcessRating, orgEnvironmentRating, healthServicesRating, likeThisProduct, isAnonymous } = cacheData
            const hours24 = 24 * 60 * 60 * 1000
            if(cacheData.expiraTime){
                this.expiraTime = cacheData.expiraTime
            }
            this.$nextTick(function () {
                this.satisfactionChange(overallRating,true) // 第二个参数是标记是否从localstrage获取的，防止匿名状态错误
                this.flowChange(appointmentProcessRating)
                this.envChange(orgEnvironmentRating)
                this.serviceChange(healthServicesRating)
                this.changeAnonymous(isAnonymous)
                this.chooseLike(likeThisProduct)
            });

            if (nowTime - hours24 - this.expiraTime >= 0) {
                localStorage.removeItem(key);
            } else {
                this.evaluateList = { ...this.evaluateList, ...cacheData }
            }
        }
    }
    // 默认评分对应的文字部分
    private starText1: String = ""   // 总体满意度
    private starText2: String = ""   // 预约流程
    private starText3: String = ""   // 机构环境
    private starText4: String = ""   // 医护服务
    private commentDefault = "满意你就夸一夸，点击这里输入评价"
    private contentDisplay: boolean = false
    private textArray: Map<number | string, string> = new Map([
        [1, "非常不满意"],
        [2, "不满意"],
        [3, "一般"],
        [4, "满意"],
        [5, "非常满意"],
    ]);
    /**
     * @function starChange
     * @description 满意度评分
     */
    private satisfactionChange(value: any,cache?: boolean) {
        this.contentDisplay = true
        this.starText1 = this.textArray.get(value) || ""
        if (value === 5) {
            if(cache===false){
                this.evaluateList.isAnonymous = false;
            }
            this.evaluateList.appointmentProcessRating = value;
            this.starText2 = this.textArray.get(value)
            this.evaluateList.orgEnvironmentRating = value;
            this.starText3 = this.textArray.get(value)
            this.evaluateList.healthServicesRating = value;
            this.starText4 = this.textArray.get(value)
            this.chooseLike(true)
            this.commentDefault = "满意你就夸一夸，点击这里输入评价"
        }
        if (value === 3 || value === 4) {
            this.commentDefault = "满意你就夸一夸，点击这里输入评价"
            if(cache===false){
                this.evaluateList.isAnonymous = false;
            }
            this.evaluateList.appointmentProcessRating = 0;
            this.starText2 = this.textArray.get(0)
            this.evaluateList.orgEnvironmentRating = 0;
            this.starText3 = this.textArray.get(0)
            this.evaluateList.healthServicesRating = 0;
            this.starText4 = this.textArray.get(0)
            this.chooseLike(null)
        }
        if (value === 1 || value === 2) {
            this.commentDefault = "点击这里输入问题，帮助服务网点改善"
            if(cache===false){
                this.evaluateList.isAnonymous = true;
            }
            this.evaluateList.appointmentProcessRating = 0;
            this.starText2 = this.textArray.get(0)
            this.evaluateList.orgEnvironmentRating = 0;
            this.starText3 = this.textArray.get(0)
            this.evaluateList.healthServicesRating = 0;
            this.starText4 = this.textArray.get(0)
            this.chooseLike(null)
        }
    }

    /**
     * @function starChange
     * @description 满意度评分
     */
    private flowChange(value: any) {
        this.evaluateList.appointmentProcessRating = value;
        this.starText2 = this.textArray.get(value) || ""
    }

    /**
     * @function starChange
     * @description 环境评分
     */
    private envChange(value: any) {
        this.evaluateList.orgEnvironmentRating = value;
        this.starText3 = this.textArray.get(value) || ""
    }

    /**
     * @function starChange
     * @description 服务评分
     */
    private serviceChange(value: any) {
        this.evaluateList.healthServicesRating = value
        this.starText4 = this.textArray.get(value) || ""
    }

    // 默认图标颜色
    private iconColar1 = '#333333'
    private iconColar2 = '#333333'

    //  默认赞和踩的动画效果状态
    private isLiked = false
    private disLiked = false
    /**
     * @function chooseLike
     * @description 修改喜欢还是不喜欢的结果
     */
    private chooseLike(data: boolean) {
        if (data === true) {
            this.disName = "good-job-o"
            this.iconColar1 = '#FF7F19'
            this.iconColar2 = '#333333'
            this.evaluateList.likeThisProduct = true
            this.isLiked = true
            setTimeout(() => {
                this.isLiked = false;
            }, 500);
        } else if (data === false) {
            this.disName = "good-job"
            this.iconColar1 = '#333333'
            this.iconColar2 = '#C7C7C7'
            this.evaluateList.likeThisProduct = false
            this.disLiked = true
            setTimeout(() => {
                this.disLiked = false;
            }, 500);
        } else {
            this.disName = "good-job-o"
            this.iconColar1 = '#333333'
            this.iconColar2 = '#333333'
            this.evaluateList.likeThisProduct = null
        }
    }

    /**
     * @function submitEvaluate
     * @description 提交评价
     */
    protected async submitEvaluate(): Promise<void> {
        const params = {
            orderId: this.orderId,
            isAnonymous: this.evaluateList.isAnonymous,
            overallRating: this.evaluateList.overallRating || 0,
            appointmentProcessRating: this.evaluateList.appointmentProcessRating || 0,
            orgEnvironmentRating: this.evaluateList.orgEnvironmentRating || 0,
            healthServicesRating: this.evaluateList.healthServicesRating || 0,
            comment: this.evaluateList.comment || "",
            likeThisProduct: this.evaluateList.likeThisProduct
        }
        try {
            await OrderService.instance.evaluateAdd(params);
            const toast = Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: '提交成功',
            });
            this.submited = true;
            let second = 3;
            let type = "4"
            const timer = setInterval(() => {
                second--;
                if (second) {
                    toast.message = `提交成功`;
                } else {
                    clearInterval(timer);
                    router.push({ name: "order-list", query: { type } })
                    // 手动清除 Toast
                    Toast.clear();
                }
            }, 1000);
        }
        catch (err) {
            Toast(err);
        }
    }

    /**
     * @function cacheEvaluate
     * @description 没评价完的数据保存24小时
     */
    protected cacheEvaluate() {
        const key = this.orderId.toString()
        let data = this.evaluateList;
        const time = new Date().getTime()
        data.expiraTime = time
        const cacheData = JSON.parse(localStorage.getItem(key))

        if (this.submited === true && (cacheData && cacheData.orderId)) {   // 提交过或者查看评价进来，返回时不存已评价的内容
            localStorage.removeItem(key)
        } else if (this.submited === false) {
            localStorage.setItem(key, JSON.stringify({}))
            localStorage.setItem(key, JSON.stringify(data))
        }
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }
}
